import React from "react"
import styles from "./content.module.scss"
import Container from "../container/container"

const Content = props => {
  const sections = props.sections
  return (
    <div>
      <Container>
        {sections.map((section, key) => (
          <div className={styles.content__projects__project}>
            <div className={styles.content__projects__project__text}>
              <h2>{section.Heading}</h2>
              <p>{section.Body}</p>
            </div>
            <div
              className={styles.content__projects__project__backgroundImage}
              style={{ backgroundImage: `url(${section.Image.publicURL})` }}
            />
          </div>
        ))}
      </Container>
    </div>
  )
}

export default Content
