import React, { useState } from "react"
import { graphql } from "gatsby"
import Banner from "../components/banner/banner"
import Footer from "../components/footer/footer"
import Testimonial from "../components/testimonial/testimonial"
import Nav from "../components/navigation/navigation"
import Content from "../components/case-study/content"

const CaseStudiesPage = ({ data }) => {
  const [showNav, setShowNav] = useState(false)
  const bgClass = "caseStudy"
  console.log(bgClass)

  return (
    <div>
      <Banner
        logo={data.strapiGlobal.logo.publicURL}
        email={data.strapiGlobal.email}
        title={data.strapiCaseStudies.Title}
        bgClass={bgClass}
        openNav={() => setShowNav(true)}></Banner>
      {showNav ? (
        <Nav
          logo={data.strapiGlobal.logo.publicURL}
          close={() => setShowNav(false)}></Nav>
      ) : null}

      <Content
        img={data.strapiCaseStudies.FeaturedImage.publicURL}
        sections={data.strapiCaseStudies.CaseStudySections}></Content>

      <Testimonial bgClass={bgClass}></Testimonial>
      <Footer
        logo={data.strapiGlobal.logo.publicURL}
        email={data.strapiGlobal.email}></Footer>
    </div>
  )
}

export default CaseStudiesPage

export const pageQuery = graphql`
  query CaseStudyTemplate($id: String!) {
    strapiCaseStudies(id: { eq: $id }) {
      id
      Title
      Slug
      FeaturedImage {
        publicURL
      }
      CaseStudySections {
        Heading
        Body
        Image {
          publicURL
        }
      }
    }
    strapiGlobal {
      email
      logo {
        publicURL
      }
    }
  }
`
